var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom bg-gray-100 gutter-b"},[_c('div',{staticClass:"card-body p-0 position-relative overflow-hidden rounded-lg"},[_c('v-img',{attrs:{"src":"/media/bg/bg-1.jpg","alt":"bg"}},[_c('h3',{staticClass:"font-weight-bolder text-white py-8 welcome-text"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.hello"))+", "+_vm._s(_vm.currentUser.email)+" ")])]),_c('div',{staticClass:"card-spacer mt-n20"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col bg-light-success px-6 py-8 rounded-xl adr-block"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-success d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Add-user.svg"}})],1),_c('router-link',{attrs:{"to":"/address/create"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-success font-weight-bold font-size-h6",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("title.createAddress"))+" ")])]}}])})],1),_c('div',{staticClass:"col bg-light-warning px-6 py-8 rounded-xl offer-block"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-warning d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Files/Selected-file.svg"}})],1),_c('router-link',{attrs:{"to":"/offer/create"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-warning font-weight-bold font-size-h6",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("title.createOffer"))+" ")])]}}])})],1)]),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col bg-light-primary px-6 py-8 rounded-xl pr-block"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-grid.svg"}})],1),_c('router-link',{attrs:{"to":"/project/create"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-primary font-weight-bold font-size-h6",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("title.createProject"))+" ")])]}}])})],1),_c('div',{staticClass:"col bg-light-danger px-6 py-8 rounded-xl inv-block"},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-danger d-block my-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Chart-bar1.svg"}})],1),_c('router-link',{attrs:{"to":"/invoice/create"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-danger font-weight-bold font-size-h6",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("title.createInvoice"))+" ")])]}}])})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }